import { render, staticRenderFns } from "./price_pane_b.vue?vue&type=template&id=519f64a5&scoped=true&"
import script from "./price_pane_b.vue?vue&type=script&lang=ts&"
export * from "./price_pane_b.vue?vue&type=script&lang=ts&"
import style0 from "./price_pane_b.vue?vue&type=style&index=0&id=519f64a5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "519f64a5",
  null
  
)

export default component.exports