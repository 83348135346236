













import { Component, Prop, Vue } from "vue-property-decorator";
import allPrice from "./all_price.vue";
@Component({
  components: {
    allPrice,
  },
})
export default class separate_price extends Vue {
  @Prop({ default: () => ({}) }) value: any; //setting-form
}
