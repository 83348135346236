



































import { Component, Prop, Vue } from "vue-property-decorator";
import priceCard from "./price_card.vue";
import pricePaneA from "./price_pane_a.vue";
import pricePaneB from "./price_pane_b.vue";
@Component({
  components: {
    priceCard,
    pricePaneA,
    pricePaneB,
  },
})
export default class all_price extends Vue {
  // props
  @Prop({ default: () => ({}) }) value: any; //setting-form
  //   METHODS
  // 改变类别重置数据
  select_price_type() {
    this.value.price_list = [
      // 定价列表
      {
        goods_category: [], // 商品分类
        sales_price: {
          //售价
          sales_price_type: '1', //定价依据：1 指导价 2 协议价
          picing_factor: 100, // 默认的价格系数
          section: [
            //区间列表
            // {  完整解释
            //   start_price: 0, //起始价
            //   end_price: 10, // 结束价
            //   fixed_price: 10, // 固定金额
            //   picing_factor: 100, //价格系数
            //   type: 2, // 1固定金额 2 价格系数
            // },
          ],
        },
        cost_price: {
          //成本价
          cost_price_type: '1', //定价依据：1 协议价 2 营销价
          picing_factor: 100, //默认的价格系数
          section: [],
        },
      },
    ];
  }

  created(){
    console.log(this.value)
  }
}
